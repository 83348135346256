import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import {
  installPrompt,
  isInstalled,
  isInstallable,
  unsetInstallPrompt,
} from "../../../utils/pwa.js";
import {
  isAndroid,
  isFirefox,
  isIOS,
  isMobile,
  isSafari,
  isSamsungBrowser,
} from "react-device-detect";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class InstallAppDialog extends React.Component {
  async handleClose(install) {
    if (install) {
      if (isInstalled() || !isInstallable()) {
        return;
      }

      if (!installPrompt) {
        console.log("prompt is empty do nothing");
        return;
      } else {
        const result = await installPrompt.prompt();
        console.log(`Install prompt was: ${result.outcome}`);
        if (result.outcome === "accepted") {
          unsetInstallPrompt();
        }
      }
    }

    if (this.props.onClose) {
      this.props.onClose(install);
    }
  }

  render() {
    const { open } = this.props;

    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Would you like to install the web app?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {isMobile && isAndroid && isFirefox && (
                <div>
                  Installing the web app will add it to your Home page. It will
                  also load your data faster.
                  <br />
                  <br />
                  Open the <strong>Menu</strong> button in the Firefox toolbar,
                  then choose <strong>Add to Home Screen</strong>.
                </div>
              )}
              {isMobile && isAndroid && isSamsungBrowser && (
                <div>
                  Installing the web app will add it to your Home page. It will
                  also load your data faster.
                  <br />
                  <br />
                  Open the <strong>Menu</strong> button in the browser toolbar,
                  then choose <strong>Add page to</strong> and select{" "}
                  <strong>Home screen</strong>.
                </div>
              )}
              {!isMobile && isSafari && (
                <div>
                  Installing the web app will put it on your Dock. It will also
                  load your data faster.
                  <br />
                  <br />
                  Click the <strong>Share</strong> button in the Safari toolbar,
                  then choose <strong>Add to Dock</strong>.
                </div>
              )}
              {isMobile && isIOS && (
                <div>
                  Installing the web app will add it to your Home page. It will
                  also load your data faster.
                  <br />
                  <br />
                  Click the <strong>Share</strong> button in the toolbar, then
                  choose <strong>Add to Home Screen</strong>.
                </div>
              )}
              {installPrompt && (
                <div>
                  {isMobile
                    ? "Installing the web app will add it to your Home page. It will also load your data faster."
                    : "Installing the web app will put it on your Desktop, Start menu and Taskbar. It will also load your data faster."}
                  <br />
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          {installPrompt && (
            <DialogActions>
              <Button onClick={() => this.handleClose(false)}>No</Button>
              <Button onClick={() => this.handleClose(true)} color="primary">
                Yes
              </Button>
            </DialogActions>
          )}
          {!installPrompt && (
            <DialogActions>
              <Button onClick={() => this.handleClose(false)}>Close</Button>
            </DialogActions>
          )}
        </Dialog>
      </div>
    );
  }
}

InstallAppDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default InstallAppDialog;
